import { httpsCallable } from "firebase/functions";
import { Student } from "../entities/student.entity";
import { Tutor } from "../entities/tutor.entity";
import { functions } from "../firebase";
import { tutorRepository } from "../repositories/tutor.repository";
import { userTypesRepository } from "../repositories/user-types.repository";
import { GenericQueryReturnType, GenericUpdateReturnType } from "../store";

export const findTutors = async (
	where?: any,
	offset?: string
): Promise<GenericQueryReturnType<"tutors", Tutor[]>> => {
	try {
		const tutors = await tutorRepository.findAll(where, offset, "name");
		return { data: { tutors } };
	} catch (_) {
		console.log(_);
		return { error: true };
	}
};

export const findTutorById = async (
	id: string
): Promise<GenericQueryReturnType<'tutor', Tutor>> => {
	try {
		const tutor = await tutorRepository.findById(id);
		if (!tutor) return { error: true };
		// if (!tutor || !tutor.isAccepted) return { error: true };
		return {
			data: {
				tutor
			}
		};
	} catch (error) {
		return { error: true };
	}
};
export const findApplyingTutorById = async (
	id: string
): Promise<GenericQueryReturnType<"tutor", Tutor>> => {
	try {
		const tutor = await tutorRepository.findById(id);
		if (!tutor || tutor.isAccepted) return { error: true };
		return {
			data: {
				tutor,
			},
		};
	} catch (error) {
		return { error: true };
	}
};

export const updateTutorById = async (
	id: string,
	newDate: Partial<Tutor>
): Promise<GenericUpdateReturnType> => {
	try {
		await tutorRepository.update(id, newDate);
		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};

export const deleteTutorById = async (
	id: string
): Promise<GenericUpdateReturnType> => {
	try {
		await tutorRepository.delete(id);
		await userTypesRepository.delete(id);

		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};

export const deleteAllTutorData = async (
	tutorId: string,
): Promise<GenericUpdateReturnType> => {
	try {
		await tutorRepository.deleteAllTutorData(tutorId);
		return { data: undefined };
	} catch (error) {
		return { error: 'Error on delete tutor data' + error };
	}
}


export const countTutors = async (
	where?: any
): Promise<GenericQueryReturnType<"count", number>> => {
	try {
		// const searchQuery =
		// 	where.isAccepted == false
		// 		? where
		// 		: where.email || where.name
		// 		? { or: { email: where.email, name: where.name } }
		// 		: {};
		const count = await tutorRepository.count(where);
		return {
			data: {
				count,
			},
		};
	} catch (_) {
		return { error: true };
	}
};

export const convertTutorToStudent = async (
	tutorId: string
): Promise<GenericUpdateReturnType> => {
	try {
		const convertTutorToStudent = httpsCallable(
			functions,
			"convertTutorToStudent"
		);
		await convertTutorToStudent({ tutorId });
		return {
			data: undefined,
		};
	} catch (_) {
		console.log("reason", _);
		return {
			error: "unknown",
		};
	}
};

export const addStudentsToPrivateTutor = async (
	tutorId: string,
	students: Partial<Student>[]
): Promise<GenericUpdateReturnType> => {
	try {
		const newStudentsData = students.map((student) => ({
			id: student.id,
			email: student.email,
			mobileNo: student.mobileNo,
			name: student.name,
		}));
		await tutorRepository.addStudentsToPrivateTutor(
			tutorId,
			newStudentsData
		);
		return { data: undefined };
	} catch (error) {
		return {
			error: "unknown",
		};
	}
};
export const findPrivateTutorStudents = async (
	id: string
): Promise<GenericQueryReturnType<"students", Student[]>> => {
	try {
		const students = await tutorRepository.findPrivateTutorStudents(id);
		return {
			data: {
				students,
			},
		};
	} catch (error) {
		return {
			error: true,
		};
	}
};
export const removeStudentFromPrivateTutor = async (
	tutorId: string,
	studentId: string
): Promise<GenericUpdateReturnType> => {
	try {
		await tutorRepository.deleteStudentFromPrivateTutor(tutorId, studentId);
		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};

export const applyTutor = async (
	tutorId: string
): Promise<GenericUpdateReturnType> => {
	try {
		const acceptApplyingTutor = httpsCallable(
			functions,
			"acceptApplyingTutor"
		);
		await acceptApplyingTutor({ tutorId });
		return {
			data: undefined,
		};
	} catch (_) {
		console.log("reason", _);
		return {
			error: "unknown",
		};
	}
};

export const rejectApplyingTutor = async (
	tutorId: string
): Promise<GenericUpdateReturnType> => {
	try {
		/* const rejectApplyingTutor = httpsCallable(
		  functions,
		  "rejectApplyingTutor"
		); */
		//await rejectApplyingTutor({ tutorId });
		const convertTutorToStudent = httpsCallable(
			functions,
			'convertTutorToStudent'
		);
		await convertTutorToStudent({ tutorId });
		return {
			data: undefined
		};
	} catch (_) {
		console.log('reason', _);
		return {
			error: 'unknown'
		};
	}

};
