import { Tutor } from "../entities/tutor.entity";
import {
	applyTutor,
	convertTutorToStudent,
	countTutors,
	deleteAllTutorData,
	deleteTutorById,
	findApplyingTutorById,
	findTutorById,
	findTutors,
	rejectApplyingTutor,
	updateTutorById,
} from "../services/tutor.service";
import { baseService } from "./base.store";

export const tutorService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getTutors: builder.query<
			{ tutors: Tutor[] },
			{ offset?: string; where?: any }
		>({
			queryFn: async ({ where, offset }) =>
				await findTutors(where, offset),
			providesTags: ["Tutor"],
		}),
		getTutorById: builder.query<{ tutor: Tutor }, string>({
			queryFn: async (id) => await findTutorById(id),
		}),
		getApplyingTutorById: builder.query<{ tutor: Tutor }, string>({
			queryFn: async (id) => await findApplyingTutorById(id),
		}),
		updateTutorById: builder.mutation<
			void,
			{ id: string; newData: Partial<Tutor> }
		>({
			queryFn: async ({ id, newData }) =>
				await updateTutorById(id, newData),
		}),
		deleteTutorById: builder.mutation<void, string>({
			queryFn: async (id) => await deleteTutorById(id),
			invalidatesTags: ["Tutor"],
		}),
		deleteAllTutorData: builder.mutation<void, string>({
			queryFn: async (tutorId) => await deleteAllTutorData(tutorId),
			invalidatesTags: ["Tutor"],
		}),
		countTutors: builder.query<{ count: number }, { where?: any }>({
			queryFn: async ({ where }) => await countTutors(where),
			providesTags: ["Tutor"],
		}),
		convertTutorToStudent: builder.mutation<void, { id: string }>({
			queryFn: async ({ id }) => await convertTutorToStudent(id),
			invalidatesTags: ["Tutor", "Student"],
		}),
		applyTutor: builder.mutation<void, string>({
			queryFn: async (id) => await applyTutor(id),
			invalidatesTags: ["Tutor"],
		}),
		rejectApplyingTutor: builder.mutation<void, string>({
			queryFn: async (id) => await rejectApplyingTutor(id),
			invalidatesTags: ["Tutor"],
		}),
	}),
});

export const {
	useGetTutorsQuery,
	useGetTutorByIdQuery,
	useUpdateTutorByIdMutation,
	useDeleteTutorByIdMutation,
	useDeleteAllTutorDataMutation,
	useCountTutorsQuery,
	useConvertTutorToStudentMutation,
	useApplyTutorMutation,
	useRejectApplyingTutorMutation,
	useGetApplyingTutorByIdQuery,
} = tutorService;
