import {
    deleteDoc,
    doc,
    getDoc
} from 'firebase/firestore';
import { UserType } from '../entities/user-type.entity';
import { Repository } from '../firebase/firestore/repository';

export class UserTypesRepository extends Repository<UserType> {
    constructor() {
        super('userTypes');
    }

    async delete(
        userId: string,
    ): Promise<void> {
        const userTypeDoc = await getDoc(doc(this.collection, userId));
        await deleteDoc(userTypeDoc.ref);
    }
}

export const userTypesRepository = new UserTypesRepository();
